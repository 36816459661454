"use client";
import React, {useState} from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic';

const ClientTestimonial = dynamic(() => import('./ClientTestimonial'));
const VidioModel = dynamic(() => import('./VidioModel'));

function Testimonial({clientTestimonials, testimonials}:any) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const videoSrc = clientTestimonials?.videoUrl; 

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

  return (
    <section className="client_section testimonial gradient_bg white">
        <button  className="client_left video_btn right fit_img" data-aos="fade" data-aos-delay="100" onClick={openModal}>
            <Image src={clientTestimonials?.videoThumbnail?.sourceUrl} width={647} height={660} alt={clientTestimonials?.videoThumbnail?.altText} loading="eager"/>
            <i className="fa fa-play"></i>
        </button>
        <div className="container right_edge sections">
            <span className="top_line"></span>
            <div className="row">
                <div className="col-lg-8">
                    <div className="client_right">
                        <div className="sections_title left">
                            <h2 data-aos="fade" data-aos-delay="100">{clientTestimonials?.title}</h2>
                            <p data-aos="fade" data-aos-delay="200">{clientTestimonials?.description}</p>
                        </div>
                        <div className="text_quote white" data-aos="fade" data-aos-delay="100">
                            <div id='testimonial' className="">
                            <ClientTestimonial testimonials={testimonials}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <VidioModel videoSrc={videoSrc} isModalOpen={isModalOpen} closeModal={closeModal}/>

    </section>
  )
}

export default Testimonial
